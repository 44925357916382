<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title" v-if="userType == 1">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/MessageManage/fixed"
          >
            固定消息 /</router-link
          >
          <span class="crumbs_item crumbs_last">
            {{ operations == 3 ? "编辑" : "新建" }}固定消息</span
          >
        </span>
        <span class="title" v-if="userType == 2">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/MessageManage/sendSMS"
          >
            手机短信 /</router-link
          >
          <span class="crumbs_item crumbs_last">
            {{ operations == 3 ? "编辑" : "新建" }}短信</span
          >
        </span>
        <span class="title" v-if="userType == 3">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/MessageManage/fixedSMS"
          >
            固定短信 /</router-link
          >
          <span class="crumbs_item crumbs_last">
            {{ operations == 3 ? "编辑" : "新建" }}固定短信</span
          >
        </span>
      </div>
    </div>

    <div class="all_content_box">
      <!-- <div class="Up_Down_inner">
        <div class="all_left_name all_required">标题</div>
        <div>
          <a-input
            class="all_input all_margin-r"
            placeholder="提醒！您的账号不安全"
          />
        </div>
      </div> -->
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">内容</span>
        <div>
          <a-textarea
            v-model="formOBJ.content"
            placeholder="输入内容"
            :rows="4"
            style="resize: none"
          />
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">推送时间</span>
        <div>
          <a-date-picker
            @change="onChanges"
            placeholder="请选择推送时间"
            format="YYYY-MM-DD HH:mm:ss"
            v-model="formOBJ.pushTime"
            :disabled-date="disabledDate"
            :showTime="{
              initialValue: formOBJ.pushTime
                ? moment('00:00:00', 'HH:mm:ss')
                : null,
            }"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">推送人群：</span>
        <div class="right-box">
          <div class="box-first">
            <div class="right_Div">
              <a-radio
                :checked="
                  formOBJ.pushPeopleType == 1 ||
                  formOBJ.pushPeopleType == 2 ||
                  formOBJ.pushPeopleType == 3 ||
                  formOBJ.pushPeopleType == 4
                "
                :value="1"
                @change="onChange($event, 'radio')"
                >指定用户</a-radio
              >
            </div>
          </div>
          <div class="Up_Down_inner">
            <span class="all_left_name">
              <span>身份：</span>
              <a-select
                :value="formOBJ.pushPeopleType"
                placeholder="从业人员"
                @change="onChange($event, 'select')"
              >
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="1"> 全部 </a-select-option>
                <a-select-option :value="3"> 从业者 </a-select-option>
                <a-select-option :value="2"> 家长 </a-select-option>
                <a-select-option :value="4"> 机构 </a-select-option>
                <a-select-option :value="5" :disabled="true">
                  {{ null }}
                </a-select-option>
              </a-select>
            </span>
          </div>
          <div class="Up_Down_inner">
            <span class="all_left_name">
              <a-radio
                :checked="formOBJ.pushPeopleType == 5"
                :value="5"
                @change="onChange($event, 'radio')"
                >指定手机号</a-radio
              >
              <a-input
                v-model="formOBJ.userTelePhone"
                class="all_input all_margin-r"
                placeholder="手机号不能为空,填写用户手机号，每个用户以“,”隔开"
              />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button
        type="primary"
        class="btn"
        @click="pushData()"
        :loading="loading"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      value: 1,
      operation: 1,
      // 传输数据
      formOBJ: {
        content: "", //	短信内容
        // creator: 0, //创建人
        // customMessageId: 0, //短信id
        // modifier: 0, //更新人
        pushPeopleType: 1, //推送人群类型 1全部 2从业者 3家长 4机构 5指定用户
        pushTime: "", //推送时间
        userTelePhone: "", //多用户手机号，用逗号分隔
      },
    };
  },
  // 事件处理器
  methods: {
    moment,
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    // 时间选择
    onChanges(date, dateString) {
      // console.log(date, dateString);
      this.formOBJ.pushTime = dateString;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().subtract(1, "days").endOf("day");
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    },
    handleChange(value) {
      // console.log(`selected ${value}`);
    },
    onChange(e, type) {
      if (type == "select") {
        this.formOBJ.pushPeopleType = e;
      } else {
        this.formOBJ.pushPeopleType = e.target.value;
      }
    },
    // 提交/编辑
    pushData() {
      if (!this.formOBJ.content) {
        this.$message.warning("请正确填写内容");
        return;
      }
      if (!this.formOBJ.pushTime) {
        this.$message.warning("请输入推送时间");
        return;
      }
      if (
        this.moment(this.formOBJ.pushTime).format("YYYY-MM-DD HH:mm:ss") <
        this.moment().locale("zh-cn").format("YYYY-MM-DD HH:mm:ss")
      ) {
        this.$message.warning("推送时间不可小于当前时间");
        return;
      }
      if(this.loading) { 
        return
      }
      this.loading = true;
      let urls = "";
      let method = "";
      // 只有1的时候是新建
      if (this.operations != 1) {
        urls = "/hxclass-management/custom/short/message/manage";
        method = "put";
      } else {
        urls = "/hxclass-management/custom/short/message/manage";
        method = "post";
      }
      let strying = this.formOBJ.userTelePhone;
      let reg = new RegExp("，", "g"); //g代表全部
      let newMsg = JSON.stringify(strying).replace(reg, ",");
      var regs = new RegExp('"', "g");
      newMsg = newMsg.replace(regs, "");
      // 避免特殊字段渲染到页面上
      let arrat = {};
      for (const key in this.formOBJ) {
        arrat[key] = this.formOBJ[key];
      }
      arrat.userTelePhone = "HK[]/MAC[]/TW[]/CN[" + newMsg + "]";
      this.$ajax({
        url: urls,
        method: method,
        params: arrat,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      });
    },
    // 获取消息详情
    getDetail(e) {
      this.$ajax({
        url: "/hxclass-management/custom/short/message/manage/details/" + e,
        method: "get",
        params: {},
      }).then((res) => {
        // console.log(res.data);
        if (res.code == 200 && res.success) {
          for (let key in res.data) {
            this.formOBJ[key] = res.data[key];
          }
          this.formOBJ.userTelePhone = res.data.ch;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.userType = this.$route.query.userType;
    this.operations = this.$route.query.operation;
    this.id = this.$route.query.id;
    if (this.operations != 1) {
      this.getDetail(this.id);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .all_content_box {
    .Up_Down_inner {
      margin-top: 14px;
      display: block;

      textarea.ant-input {
        width: 525px;
        height: 192px;
      }

      .all_input {
        width: 397px;
      }

      .ant-calendar-picker,
      .input {
        width: 397px;
      }
    }

    .L_R_inner {
      .right-box {
        .box-first {
          width: 471px;

          .right_Div {
            display: inline-block;
            line-height: 37px;

            .ant-select {
              width: 166px;
            }
          }
        }

        .Up_Down_inner {
          margin-left: 20px;

          .all_left_name {
            display: flex;
            align-items: center;

            .ant-select {
              width: 228px;
            }
          }
        }
      }
    }
  }

  .button {
    .btn {
      margin: 24px 14px;
    }
  }
}
</style>
